<script lang="ts" setup>
import { promiseTimeout } from '@vueuse/core'

const props = defineProps<{ title?: string, modelValue: any, options: { title: string, value: any }[] }>()
const emits = defineEmits(['update:modelValue'])

const value = useVModel(props, 'modelValue', emits)
const isBoxLoaded = ref<boolean>(true)

async function changeValue (newValue: string | number) {
  if (newValue === value.value)
    return

  value.value = newValue
  isBoxLoaded.value = false
  await promiseTimeout(200)
  isBoxLoaded.value = true
}
</script>

<template>
  <div flex flex-col gap-8px items-start>
    <h2 v-if="title" text="14px app-text-dark" font-500 leading-16px>
      {{ title }}
    </h2>
    <div
      v-auto-animate bg-white rounded-full border="1px app-stroke" h-40px w-full relative grid grid-cols-2
      place-items-center
    >
      <div
        v-for="option in options" :key="option.value" :class="{ 'text-white! font-700!': isBoxLoaded && option.value === value }"
        text-app-text-black
        font-500 cursor-pointer z-10 @click="changeValue(option.value)"
      >
        {{ option.title }}
      </div>
      <div v-if="value === options[0].value" absolute bg-app-blue top-4px left-4px w="1/2" h-30px rounded-full />
      <div v-if="value === options[1].value" absolute bg-app-blue top-4px right-4px w="1/2" h-30px rounded-full />
    </div>
  </div>
</template>
