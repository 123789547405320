const initialState = ref<NavigationTab[]>([
  {
    no: 1,
    title: 'Základní nastavení',
    subtitle: 'Hlavní konfigurace skříně a rozměrů',
    state: 'active',
  },
  {
    no: 2,
    title: 'Výplň',
    subtitle: 'Vyberte druhy výpně a roštů',
    state: 'inactive',
  },
  {
    no: 3,
    title: 'Ventilace',
    subtitle: 'Umístění ventilace ve skříni',
    state: 'inactive',
  },
  {
    no: 4,
    title: 'Prostupy',
    subtitle: 'Prostupy v příslušném rozměru a umístění',
    state: 'inactive',
  },
  {
    no: 5,
    title: 'Cena za konfiguraci',
    subtitle: '',
    state: 'inactive',
  },
])

export const useConfigNavigation = defineStore('configNavigation', () => {
  const tabs = useState<NavigationTab[]>(() => initialState)

  const highestIndexReached = ref(0)

  const activeTab = computed(() => tabs.value.find(tab => tab.state === 'active')!)

  const { caseConfig, allConfigs, configsInitialized } = storeToRefs(useCaseConfig())

  function nextTab () {
    const index = tabs.value.indexOf(tabs.value.find(tab => tab.state === 'active')!)

    highestIndexReached.value = index + 1

    if (index >= 4)
      return

    tabs.value[index].state = 'done'
    tabs.value[index + 1].state = 'active'

    caseConfig.value.step = tabs.value.indexOf(activeTab.value) + 1
  }

  function selectTab (tabNumber: number, force?: boolean) {
    const newActiveIndex = tabs.value.indexOf(tabs.value.find(tab => tab.no === tabNumber)!)

    if (newActiveIndex > highestIndexReached.value && !force)
      return

    highestIndexReached.value = newActiveIndex + 1

    for (const tab of tabs.value) {
      const index = tabs.value.indexOf(tab)
      tab.state = index === newActiveIndex ? 'active' : index < newActiveIndex ? 'done' : 'inactive'
    }

    caseConfig.value.step = tabs.value.indexOf(activeTab.value) + 1
  }

  const reset = () => {
    selectTab(1)
  }

  watch(allConfigs, () => {
    if (allConfigs.value.length === 0)
      reset()
  })

  watch(caseConfig, async () => {
    await until(configsInitialized).toBe(true)

    if (!caseConfig.value.caseInfo || !caseConfig.value.caseType) {
      reset()
      return
    }
    selectTab(caseConfig.value.step!, true)
  }, {
    immediate: true,
  })

  return {
    tabs,
    activeTab,
    doneTabs: computed(() => tabs.value.filter(tab => tab.state === 'done')),
    inactiveTabs: computed(() => tabs.value.filter(tab => tab.state === 'inactive')),
    nextTab,
    selectTab,
    reset,
  }
})

export interface NavigationTab {
  no: number
  title: string
  subtitle: string
  state: 'active' | 'inactive' | 'done'
}
