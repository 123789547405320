import type { Case } from '~/types'
import { CustomSizeType, SizeType } from '~/types/SizeType'

export const useStepOne = defineStore('stepOne', () => {
  const { caseConfig } = storeToRefs(useCaseConfig())
  const { cases } = storeToRefs(useRemoteConfig())

  const caseInfo = computed(() => caseConfig.value.caseInfo)

  const pickedEi = computed(() => caseConfig.value.pickedEi)
  const pickedHeight = computed(() => caseConfig.value.pickedHeight)
  const pickedWidth = computed(() => caseConfig.value.pickedWidth)
  const pickedDepth = computed(() => caseConfig.value.pickedDepth)

  const colorOptions = [{ label: 'RAL 7035 - světle šedá', value: '7035' }, { label: 'RAL 9016 - bílá', value: '9016' }]

  const reset = () => {
    caseConfig.value.sizeType = SizeType.catalog
    caseConfig.value.customSizeType = CustomSizeType.outerDimensions

    caseConfig.value.pickedEi = undefined
    caseConfig.value.caseInfo = undefined
    caseConfig.value.pickedHeight = undefined
    caseConfig.value.pickedWidth = undefined
    caseConfig.value.pickedDepth = undefined
    caseConfig.value.color = colorOptions[0].value
  }

  const filteredCasesByCaseType = computed(() => cases.value.filter(c => c.caseType === caseConfig.value.caseType))

  const availableCases = computed(() => {
    let filteredCases = filteredCasesByCaseType.value
    if (caseConfig.value.sizeType === SizeType.custom)
      return filteredCases
    if (caseConfig.value.caseInfo)
      return [caseConfig.value.caseInfo]
    if (pickedEi.value)
      filteredCases = filteredCases.filter(c => c.ei === pickedEi.value)
    if (pickedHeight.value)
      filteredCases = filteredCases.filter(c => caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? c.inDimensions.h === pickedHeight.value : c.outDimensions.h === pickedHeight.value)
    if (pickedWidth.value)
      filteredCases = filteredCases.filter(c => caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? c.inDimensions.w === pickedWidth.value : c.outDimensions.w === pickedWidth.value)
    if (pickedDepth.value)
      filteredCases = filteredCases.filter(c => caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? c.inDimensions.d === pickedDepth.value : c.outDimensions.d === pickedDepth.value)

    return filteredCases
  })

  const eiOptions = computed(() => {
    const uniqueEi = new Set<number>()
    for (const casee of cases.value.filter(c => caseConfig.value.caseInfo ? c.label.split('-EI')[0] === caseConfig.value.caseInfo.label.split('-EI')[0] : c.caseType === caseConfig.value.caseType)) {
      uniqueEi.add(casee.ei)
    }

    if (caseConfig.value.isCustomDimensions) {
      [30, 45, 60].forEach(ei => uniqueEi.add(ei))
      if (caseConfig.value.isSp || caseConfig.value.isSr)
        uniqueEi.add(90)
    }
    return [...uniqueEi.values()].map(ei => ({ label: ei.toString(), value: ei }))
  })

  const caseOptions = computed(() => (caseConfig.value.caseInfo ? cases : availableCases).value.map(c => ({ label: c.label.replaceAll('DP1-S(a) / S(200)', ''), value: c })))

  const heightOptions = computed(() => {
    const uniqueHeight = new Set<number>()

    for (const casee of availableCases.value) {
      const heightByType = caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? casee.inDimensions.h : casee.outDimensions.h
      if (uniqueHeight.has(heightByType))
        continue
      uniqueHeight.add(heightByType)
    }
    return [...uniqueHeight.values()].map(h => ({ label: h.toString(), value: h }))
  })

  const widthOptions = computed(() => {
    const uniqueWidth = new Set<number>()

    for (const casee of availableCases.value) {
      const widthByType = caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? casee.inDimensions.w : casee.outDimensions.w
      if (uniqueWidth.has(widthByType))
        continue
      uniqueWidth.add(widthByType)
    }

    return [...uniqueWidth.values()].map(w => ({ label: w.toString(), value: w }))
  })

  const depthOptions = computed(() => {
    const uniqueDepth = new Set<number>()

    for (const casee of availableCases.value) {
      const depthByType = caseConfig.value.customSizeType === CustomSizeType.innerDimensions ? casee.inDimensions.d : casee.outDimensions.d
      if (uniqueDepth.has(depthByType))
        continue
      uniqueDepth.add(depthByType)
    }

    return [...uniqueDepth.values()].map(d => ({ label: d.toString(), value: d }))
  })

  const prefillDataFromCaseInfo = async (ci: Case) => {
    caseConfig.value.pickedEi = ci.ei
    caseConfig.value.pickedWidth = ci.outDimensions.w
    caseConfig.value.pickedHeight = ci.outDimensions.h
    caseConfig.value.pickedDepth = ci.outDimensions.d
  }

  const { updateTabLabel } = useTabs()

  watch(caseInfo, () => {
    if (caseInfo.value) {
      const { allConfigs } = storeToRefs(useCaseConfig())
      updateTabLabel(allConfigs.value.indexOf(caseConfig.value), caseConfig.value.caseLabel.split('-EI')[0])
    }
  }, {
    immediate: true,
  })

  return {
    pickedEi,
    caseInfo,
    pickedHeight,
    pickedWidth,
    pickedDepth,
    reset,
    prefillDataFromCaseInfo,
    eiOptions,
    caseOptions,
    heightOptions,
    widthOptions,
    depthOptions,
    colorOptions,
  }
})
